import React, { useState, useMemo } from 'react'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid'
import useSWR from "swr"
import {  Get } from './get';
import SkeletonTable from '../skelholder/skelethonTable';
import AppContext from '../../contexts/ServiceContext';

const VISIBLE_FIELDS = ['currency', "currency_code",'current_bal'];

const columns1 = [

    {
        field: 'currency',
        headerName: 'Pais o Zona',
        width: 300,
        editable: false,
    },
    {
        field: 'currency_code',
        headerName: 'Moneda',
        width: 80,
        editable: false,
    },

    {
        field: 'current_bal',
        headerName: 'Cantidad restante',
        type: 'phone',
        width: 150,
        editable: false,

        renderCell: (params) => {

            const cant = Number(params.row.current_bal).toLocaleString("es-GQ") ;

            return cant;
        }
    },
 

];

function DataTableCHeckSW() {
    const { userId, typeUser, acciones ,AxiosConfigsToken} = React.useContext(AppContext)

    //SWR para hacer peticiones


    const { data, error, isLoading, } = useSWR("getbalanceSW",()=> Get(AxiosConfigsToken), {})

    const columns = React.useMemo(
        () => columns1.filter((column) => VISIBLE_FIELDS.includes(column.field)),
        [columns1],
    );

    if (isLoading) return <SkeletonTable />

    if (error) return <></>

    return (
        <>
            <Box sx={{ height: "auto", width: '100%',marginBlock:"20px" }}>
                <DataGrid
                    rows={data}
                    getRowId={(row) => row.fiat_wallet_id}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    disableRowSelectionOnClick
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    options={{
                        responsive: "scroll",
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible

                            },
                        },
                        pagination: { paginationModel: { pageSize: 8 } }
                    }}
                    pagination={true}
                />
            </Box>
        </>
    )
}

export default DataTableCHeckSW