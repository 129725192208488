import React, { useState, useMemo } from 'react'
import { Box, Button, IconButton, Stack } from '@mui/material'
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid'
import { Add, Delete, Edit } from '@mui/icons-material';
import ModalAddUser from './modalAddValorMoneda';
import useSWR from "swr"
import {  GetValorMoneda } from './getValorMoneda';
import SkeletonTable from '../skelholder/skelethonTable';
import AppContext from '../../contexts/ServiceContext';
import ModalAddFormUpdateValorMoneda from './modalFormUpdateValorMoneda';
import ModalAddValorMoneda from './modalAddValorMoneda';

const VISIBLE_FIELDS = ['name', "Acciones",'nameAdminRegister', "valor","createdAt"];

const columns1 = [
    {
        field: 'createdAt',
        headerName: 'Fecha',
        type: "date",
        width: 140,
        editable: false,
        valueGetter: (params) => new Date(params.row.createdAt),
        renderCell: (params) => {
            const mes = Number(new Date(params.row.createdAt).getMonth()) + 1;
            const dia = Number(new Date(params.row.createdAt).getDate());
            const agno = Number(new Date(params.row.createdAt).getFullYear());
            const hora = new Date(params.row.createdAt).getHours();
            const min = new Date(params.row.createdAt).getMinutes();

            return (dia + '/' + mes + '/' + agno + '  ' + hora + ':' + min);
        },
    },
    {
        field: 'name',
        headerName: 'Role',
        width: 180,
        editable: false,
    },
    {
        field: 'valor',
        headerName: 'Valor',
        width: 180,
        editable: false,
    },
    {
        field: 'nameAdminRegister',
        headerName: 'Registrado por',
        width: 180,
        editable: false,
    },
    {
        field: "Acciones",
        headerName: 'Acciones',
        width: 180,
        editable: false,
        renderCell: (params) => {
            const currentRow = params.row;

            return (
                <>
                    {
                        <>
                           
                            {/*<ModalDeleteUser dataUser={currentRow} />*/}
                        </>
                    }

                </>
            );
        },
    },

];

function DataTableValorMoneda() {
    const { userId, typeUser, acciones ,AxiosConfigsToken} = React.useContext(AppContext)

    //SWR para hacer peticiones

    const [openFormUpdate, setOpenFormUpdate] = React.useState(false);

    const { data, error, isLoading, } = useSWR("getMoneda",()=> GetValorMoneda(AxiosConfigsToken), {})

    const columns = React.useMemo(
        () => columns1.filter((column) => VISIBLE_FIELDS.includes(column.field)),
        [columns1],
    );

    if (isLoading) return <SkeletonTable />

    if (error) return <></>

    return (
        <>
            <ModalAddValorMoneda />
            <Box sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={data}
                    getRowId={(row) => row._id}
                    disableColumnFilter
                    disableColumnSelector
                    disableDensitySelector
                    columns={columns}
                    slots={{ toolbar: GridToolbar }}
                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                    disableRowSelectionOnClick
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                    options={{
                        responsive: "scroll",
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible

                            },
                        },
                        pagination: { paginationModel: { pageSize: 8 } }
                    }}
                    pagination={true}
                />
            </Box>
        </>
    )
}

export default DataTableValorMoneda