import axios from "axios"
import axiosConfigs from "../axiosConfig"



export const GetValorMoneda = async (AxiosConfigsToken) => {
    const res = await AxiosConfigsToken.get('/obtener_monedaS')
    const data = res.data.data.docs
    //console.log(res,'sssssssss')
    return data
}