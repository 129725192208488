import { Grid, Typography } from '@mui/material'
import React from 'react'
import { Bar, Line, Pie, PolarArea, Bubble, Radar, Scatter } from 'react-chartjs-2'

function ChartAdmin({ data_gra, meses, graph_title }) {

    var dataGraph = []
    var i = 1
    for (let x of data_gra) {
        dataGraph.push(
            {
                id: i,
                label: x.title,
                data: x.data,
                //backgroundColor: x.backg,
                borderColor: x.backg,
                tension: 0.5,
                pointRadius: 5,
                fill: false,
                tension: 0.1

            })

        i = i + 1
    }

    return (
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ marginTop: '30px' }}>
            <Grid>
                <Typography variant='h5' sx={{ textAlign: "center", fontFamily: "inherit", marginBottom: '20px' }}>{graph_title}</Typography>

            </Grid>
            <Line
                datasetIdKey='id'
                data={{
                    labels: meses,
                    datasets: dataGraph,

                }}

            />
        </Grid>
    )
}

export default ChartAdmin