import React, { useEffect, useContext, useState } from 'react'
import "./home.css"
import { Link, useNavigate } from 'react-router-dom';
import AppContext from '../../contexts/ServiceContext';
import axios from 'axios';
import { DATA_GRAPHIQUE, URL_SERVER, arrayAdminView } from '../../contexts/constantesVar';
import SpinnerAlls from '../../components/spinnerAll/spinnerAlls';
import { PulseLoader } from 'react-spinners';
import toast, { Toaster } from 'react-hot-toast';
import axiosConfigs from '../../components/axiosConfig';
import { Box, Grid } from '@mui/material';
import MenuAppBars from '../../components/appBar/appBarr';
import CardHome from '../../components/cardHome';
import { Group, Payment, Payments, PeopleAlt } from '@mui/icons-material';
import CharTransfertBar from '../../components/char/charTransfertBar';
import CharArea from '../../components/char/charArea';
import SkelethonCard from '../../components/skelholder/skelethonCardHome';
import useSWR from "swr"
import { GetDataHome, GetDataHomeMaster } from './getDataHome';
import { arrayMaster } from '../../contexts/constantesVar';
import GrafigReporteHomeAdmin from '../../components/reporte/grafigReporteHomeAdmin';
import { Line } from 'react-chartjs-2';
import ChartAdmin from '../../components/graphig/chartAdmin';
import DataTableCHeckSW from '../../components/checkBalanceSW/dataTable';

function Home() {

  const { typeUser, valideLogin, userId, userName, userCode, userPhone, dispatch, acciones, AxiosConfigsToken, ObtenervalorMoneda } = useContext(AppContext)

  const navigate = useNavigate();

  const [mesTotal, setMesTotal] = useState([])
  const [totalData, setTotalData] = useState([])
  const [totalDataVerify, setTotalDataVerify] = useState(false)
  var meses = []
  var data_gr = []

  const DataGraphique = async () => {
    setTotalDataVerify(false)
    try {
      const datos_graphiq = await AxiosConfigsToken.get('/reporte_operaciones')

      if (datos_graphiq.data.verificar) {
        //console.log(datos_graphiq.data.data.docs)
        if (datos_graphiq.data.data.docs[0]) {
          setTotalData(datos_graphiq.data.data.docs[0])
          setTotalDataVerify(true)
        } else {
          setTotalDataVerify(false)

          setTotalData([])

        }
      } else {
        setTotalDataVerify(false)

      }
    } catch (error) {

      setTotalDataVerify(false)

    }

  }


  useEffect(() => {
    DataGraphique()
    if (JSON.parse(window.localStorage.getItem("enableTCaja"))) {
    } else {
      window.localStorage.setItem("enableTCaja", JSON.stringify({ valor: false, valorI: "", nameI: '', typeI: '', phoneI: '' }))
    }
    ObtenervalorMoneda()
  }, [])

  if (userId && arrayMaster.includes(typeUser)) {
    const { data, error, isLoading, } = useSWR(["getDataHomeMaster", userId], () => GetDataHomeMaster(userId, AxiosConfigsToken), {})

    //if (isLoading) return <SkeletonTable />

    //if (error) return <></>

    return (
      <>
        {acciones.includes('ver_info') ?
          <>
            <Grid

              spacing={1}
              bgcolor="backgroundColorPage"

              container
            >
              {/*isLoading ?
              <>
                {
                  error ?
                    <></>
                    :
                    <SkelethonCard />

                }
              </>
              :
              <CardHome IconHome={PeopleAlt} cantidad={data.master ?Number(data.master).toLocaleString("es-GQ") : 0} colorIcon="#ff9800" titleCard="Total Masters" />
              */}

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {data ?
                    <CardHome IconHome={Group} cantidad={data.caja ? Number(data.caja).toLocaleString("es-GQ") : 0} colorIcon="#5d4037" titleCard="Total Cajas" />

                    :
                    <></>
                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {data ?
                    <CardHome IconHome={Payment} cantidad={data.saldo_rest ? Number(data.saldo_rest).toLocaleString("es-GQ") + '  ' + 'XAF' : 0} colorIcon='#091945' titleCard="Total Saldo Nacional" />

                    :
                    <></>
                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {data ?
                    <CardHome IconHome={Payment} cantidad={data.saldo__inter_master ? Number(data.saldo__inter_master).toLocaleString("es-GQ") + '  ' + 'XAF' : 0} colorIcon='#091945' titleCard="Total Saldo Internacional" />

                    :
                    <></>
                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {data ?
                    <CardHome IconHome={Payments} cantidad={data.interesTotal ? Number(data.interesTotal).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon="#091945" titleCard="Total int. de cajas" />

                    :
                    <></>
                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {data ?
                    <CardHome IconHome={Payments} cantidad={data.saldo_caja ? Number(data.saldo_caja).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon="#091945" titleCard="Total saldo de cajas" />

                    :
                    <></>
                  }
                </>
              }

            </Grid>

          </>
          :
          <></>
        }


      </>
    )
  } else {
    const { data, error, isLoading, } = useSWR("getDataHome", () => GetDataHome(AxiosConfigsToken), {})

    //if (isLoading) return <SkeletonTable />

    //if (error) return <></>



    return (
      <>
        {acciones.includes('ver_info') ?
          <>
            <Grid
              spacing={1}
              bgcolor="backgroundColorPage"

              container
            >
              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_cant_master') ?
                    <>
                      {data ?
                        <CardHome link='/users/1' IconHome={PeopleAlt} cantidad={data.master ? Number(data.master).toLocaleString("es-GQ") : 0} colorIcon="#091945" titleCard="Total Masters" />

                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :

                <>
                  {acciones.includes('ver_cant_cajas') ?
                    <>
                      {data ?
                        <CardHome link='/users/2' IconHome={Group} cantidad={data.caja ? Number(data.caja).toLocaleString("es-GQ") : 0} colorIcon="#091945" titleCard="Total Cajas" />


                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_saldo_rest') ?
                    <>
                      {data ?
                        <CardHome link={`/caja_de_empresa/${userId}`} IconHome={Payment} cantidad={data.saldo_rest ? Number(data.saldo_rest).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon='#e65100' titleCard="Total Saldo Nacional" />


                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_saldo_rest') ?
                    <>
                      {data ?
                        <CardHome link={`/caja_de_empresa/${userId}`} IconHome={Payment} cantidad={data.saldo__inter_admin ? Number(data.saldo__inter_admin).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon='#e65100' titleCard="Total Saldo Internacional" />


                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }



              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_saldo_rest') ?
                    <>
                      {data ?
                        <CardHome IconHome={Payment} cantidad={data.saldo_restante_inter ? Number(data.saldo_restante_inter).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon='#e65100' titleCard="Total Saldo INTOUCH AF_C" />


                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }









              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_saldo_total_master') ?
                    <>
                      {data ?

                        <CardHome link='/users/1' IconHome={Payment} cantidad={data.saldo_master ? Number(data.saldo_master).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon='#e65100' titleCard="Total Saldo masters" />

                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }
              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_saldo_total_cajas') ?
                    <>
                      {data ?
                        <CardHome link='/users/1' IconHome={Payment} cantidad={data.saldo_caja ? Number(data.saldo_caja).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon='#e65100' titleCard="Total Saldo cajas" />


                        :
                        <></>
                      }
                    </>
                    :
                    <></>

                  }
                </>
              }
              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_saldo_op_pending') ?
                    <>
                      {data ?

                        <CardHome link='/transaction_nacionales/5' IconHome={Payment} cantidad={data.saldo_envios_pending ? Number(data.saldo_envios_pending).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon='#e65100' titleCard="Total saldo op. pendiente" />

                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }
              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_interes') ?
                    <>
                      {data ?
                        <CardHome link='transaction_nacionales/1' IconHome={Payments} cantidad={data.interesTotal ? Number(data.interesTotal).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon="#b71c1c" titleCard="Total int. nacional" />

                        :
                        <></>
                      }
                    </>
                    :
                    <></>

                  }
                </>
              }
              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_interes') ?
                    <>
                      {data ?
                        <CardHome IconHome={Payments} cantidad={data.interesInt ? Number(data.interesIntSW).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon="#b71c1c" titleCard="Total int. interna." />

                        :
                        <></>
                      }
                    </>
                    :
                    <></>

                  }
                </>
              }

              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_total_interes_socios') ?
                    <>
                      {data ?
                        <CardHome IconHome={Payments} cantidad={data.interes_socios ? Number(data.interes_socios).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon="#b71c1c" titleCard="Total interes socios" />

                        :
                        <></>
                      }
                    </>
                    :
                    <></>

                  }
                </>
              }
              {isLoading ?
                <>
                  {
                    error ?
                      <></>
                      :
                      <SkelethonCard />

                  }
                </>
                :
                <>
                  {acciones.includes('ver_iva') ?
                    <>
                      {data ?
                        <CardHome IconHome={Payments} cantidad={data.ivaTotal ? Number(data.ivaTotal).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon="#5d4037" titleCard="Total iva Nacional" />
                        :
                        <></>
                      }

                    </>
                    :
                    <></>

                  }
                </>
              }


            </Grid>

            <>
              <Grid
                bgcolor="#fff"
                sx={{}}
              >
                <DataTableCHeckSW />
              </Grid>
            </>

            
            <Grid
              spacing={1}
              bgcolor="backgroundColorPage"

              container
            >

              {totalData.data && totalDataVerify ?
                <ChartAdmin graph_title={'Numero de envios por mes'} meses={totalData.data.map((s) => s.mes)} data_gra={[
                  {
                    data: totalData.data.map((s) => s.value.total_envi_nac),
                    title: 'Envios Nacionales',
                    backg: '#ff9800'
                  },
                  {
                    data: totalData.data.map((s) => s.value.total_envi_Loov),
                    title: 'Envios Internacionales',
                    backg: '#00113d'
                  },
                  /*
                  {
                    data: totalData.data.map((s) => s.value.total_envi_Mo_Ex),
                    title: 'Envios Money Exchange',
                    backg: '#2196f3'
                  },
                  */
                  {
                    data: totalData.data.map((s) => s.value.total_recep_nac),
                    title: 'Recepciones nacionales',
                    backg: '#00e676'
                  }
                ]} />
                :
                <></>
              }




              {totalData.data && totalDataVerify ?
                <ChartAdmin graph_title={'Total saldo envios por mes'} meses={totalData.data.map((s) => s.mes)} data_gra={[
                  {
                    data: totalData.data.map((s) => s.value.total_sald_env_nac),
                    title: 'Total saldo Nacional',
                    backg: '#ff9800'
                  },
                  {
                    data: totalData.data.map((s) => s.value.total_sald_env_Loov),
                    title: 'Total saldo Internacional',
                    backg: '#00113d'
                  },
                  /*
                  {
                    data: totalData.data.map((s) => s.value.total_sald_env_MoEx),
                    title: 'Total saldo Money Exchange',
                    backg: '#2196f3'
                  }

                    */
                ]} />
                :
                <></>
              }

              {totalData.data && totalDataVerify ?
                <ChartAdmin graph_title={'Total interes envios por mes (GLOBAL EXCH.)'} meses={totalData.data.map((s) => s.mes)} data_gra={[
                  {
                    data: totalData.data.map((s) => s.value.total_inte_nac_gmt),
                    title: 'Total int. Nacional',
                    backg: '#ff9800'
                  },
                  {
                    data: totalData.data.map((s) => s.value.total_inte_Loov_gmt),
                    title: 'Total int. Intern.',
                    backg: '#00113d'
                  },

                  /*
                  {
                    data: totalData.data.map((s) => s.value.total_inte_Mo_Ex_gmt),
                    title: 'Total interes Money Exchange',
                    backg: '#2196f3'
                  }
                    
                  */
                ]} />
                :
                <></>
              }

              {totalData.data && totalDataVerify ?
                <ChartAdmin graph_title={'Total interes envios por mes (SOCIOS)'} meses={totalData.data.map((s) => s.mes)} data_gra={[
                  {
                    data: totalData.data.map((s) => s.value.total_inte_nac_sc),
                    title: 'Total interes Nacional',
                    backg: '#ff9800'
                  },
                  {
                    data: totalData.data.map((s) => s.value.total_inte_Loov_sc),
                    title: 'Total int. intern.',
                    backg: '#00113d'
                  },
                  {
                    data: totalData.data.map((s) => s.value.total_inte_Mo_Ex_sc),
                    title: 'Total interes Money Exchange',
                    backg: '#2196f3'
                  }
                ]} />
                :
                <></>
              }


              {totalData.data && totalDataVerify ?
                <ChartAdmin graph_title={'Total iva por mes'} meses={totalData.data.map((s) => s.mes)} data_gra={[
                  {
                    data: totalData.data.map((s) => s.value.total_iva_nac_gmt),
                    title: 'Total iva Nacional',
                    backg: '#ff9800'
                  },
                  {
                    data: totalData.data.map((s) => s.value.total_iva_Loov_gmt),
                    title: 'Total iva Internacional',
                    backg: '#00113d'
                  },
                  /*
                  {
                    data: totalData.data.map((s) => s.value.total_iva_Mo_Ex_gmt),
                    title: 'Total iva Money Exchange',
                    backg: '#2196f3'
                  }
                    */
                ]} />
                :
                <></>
              }




            </Grid>
          </>

          :
          <></>
        }
      </>
    )
  }

}

export default Home

/*












            {isLoading ?
              <>
                {
                  error ?
                    <></>
                    :
                    <SkelethonCard />

                }
              </>
              :
              <CardHome IconHome={Payment} cantidad={data.saldo_repar ? Number(data.saldo_repar).toLocaleString("es-GQ") + ' ' + 'XAF' : 0} colorIcon='#ff9800' titleCard="Total Saldo repartido" />
            }


*/