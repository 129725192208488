


//export const URL_SERVER = process.env.NODE_ENV = 'production'?process.env.REACT_APP_API_URL:process.env.REACT_APP_API_LOCAL_URL
export const URL_SERVER = process.env.REACT_APP_API_URL01
//export const URL_SERVER = process.env.REACT_APP_API_LOCAL_URL

//export const KEY_UI = process.env.REACT_APP_KEY_UI
export const VALIDE_USER = "valideLogin"
export const NAME_USER = "userName"
export const ID_USER = "userId"
export const PHONE_USER = "userPhone"
export const CODE_USER = "userCode"
export const LOGIN_SPINNER = "loginSpinner"
export const RESP_ERROR_LOGIN = "errorResponseLogin"
export const ERROR_USER = "userError"
export const TYPE_USER = "typeUser"

export const ACCIONES = "acciones"
export const arrayMaster = ['Master_INTERNO', 'Master_FINANCIADO', 'Master_PREFINANCIADO']
export const arrayMaster_no_finc = ['Master_FINANCIADO', 'Master_PREFINANCIADO']
export const arrayAdmin = ['INFORMATICO','DIRECTIVO','COMERCIAL','Jefe_Agencia','Cajero','Cajero_admin','super_admin','CONTABLE']
export const arrayAdminView = ['super_admin']
export const TOKEN = "token"
export const DATA_GRAPHIQUE = [
    {
        year:2024,
        data:[
            {
                mes:'may',
                value:{

                    total_envi_nac:10,
                    total_envi_intouh:0,
                    total_envi_Mo_Ex:0,

                    total_inte_nac_gmt:0,
                    total_inte_intouh_gmt:0,
                    total_inte_Mo_Ex_:0,

                    total_inte_nac_sc:0,
                    total_inte_intouh_sc:0,
                    total_inte_Mo_Ex_sc:0,

                    total_iva_nac_gmt:0,
                    total_iva_intouh_gmt:0,
                    total_iva_Mo_Ex_gmt:0,

                    total_iva_nac_sc:0,
                    total_iva_intouh_sc:0,
                    total_iva_Mo_Ex_sc:0,
                }
            },
            {
                mes:'juin',
                value:{
                    
                    total_envi_nac:5,
                    total_envi_intouh:0,
                    total_envi_Mo_Ex:0,

                    total_inte_nac_gmt:0,
                    total_inte_intouh_gmt:0,
                    total_inte_Mo_Ex_:0,

                    total_inte_nac_sc:0,
                    total_inte_intouh_sc:0,
                    total_inte_Mo_Ex_sc:0,

                    total_iva_nac_gmt:0,
                    total_iva_intouh_gmt:0,
                    total_iva_Mo_Ex_gmt:0,

                    total_iva_nac_sc:0,
                    total_iva_intouh_sc:0,
                    total_iva_Mo_Ex_sc:0,
                }
            },
            {
                mes:'juill',
                value:{
                    
                    total_envi_nac:12,
                    total_envi_intouh:0,
                    total_envi_Mo_Ex:0,

                    total_inte_nac_gmt:0,
                    total_inte_intouh_gmt:0,
                    total_inte_Mo_Ex_:0,

                    total_inte_nac_sc:0,
                    total_inte_intouh_sc:0,
                    total_inte_Mo_Ex_sc:0,

                    total_iva_nac_gmt:0,
                    total_iva_intouh_gmt:0,
                    total_iva_Mo_Ex_gmt:0,

                    total_iva_nac_sc:0,
                    total_iva_intouh_sc:0,
                    total_iva_Mo_Ex_sc:0,
                }
            }
        ]
    }
]
export const DigiPhone = [
    '222',
    '333',
    '555',
    '551',
    '666',
    '550'
]

export const dataTest = [
    {
        "attributes": {
            "id": "1",
            "xsi:type": "NS2:TWStransaction"
        },
        "TransactionId": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "7003-00000007"
        },
        "TransactionDate": {
            "attributes": {
                "xsi:type": "xsd:dateTime"
            },
            "$value": "2023-12-28T13:54:12.000Z"
        },
        "SenderId": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "0001-01200851"
        },
        "Sender": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "NDONG ONDO AVOMO NDONG ONDO AVOMO"
        },
        "SenderName": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "NDONG ONDO AVOMO"
        },
        "SenderLastName": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "NDONG ONDO AVOMO"
        },
        "SenderLastName2": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "SenderAddress": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "BIYENDEM OKUME"
        },
        "SenderCity": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "DESCONOCIDA"
        },
        "SenderCountry": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "GNQ"
        },
        "SenderIdDocumentNumber": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "SenderIdDocumentType": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "SenderReference": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "PP1234PP"
        },
        "ReceiverId": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "0001-01090097"
        },
        "Receiver": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "TEST 2 SUIZA"
        },
        "ReceiverName": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "TEST"
        },
        "ReceiverLastName": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "2"
        },
        "ReceiverLastName2": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "SUIZA"
        },
        "ReceiverAddress": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "VOLTASTRASSE, 76; 4056 - BASEL"
        },
        "ReceiverCity": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "BASEL"
        },
        "ReceiverCountry": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "CHE"
        },
        "ReceiverPhone": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "0041763190042"
        },
        "ReceiverPhone2": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "ReceiverIdDocumentNumber": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "ReceiverIdDocumentType": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "ReceiverReference": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "BankDeposit": {
            "attributes": {
                "xsi:type": "xsd:boolean"
            },
            "$value": false
        },
        "BankName": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "BankAddress": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "BankAccount": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "AmountToPay": {
            "attributes": {
                "xsi:type": "xsd:double"
            },
            "$value": 50
        },
        "CurrencyToPay": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "CHF"
        },
        "AmountSent": {
            "attributes": {
                "xsi:type": "xsd:double"
            },
            "$value": 51.54
        },
        "CurrencySent": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "EUR"
        },
        "SenderMessage": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "Prueba test 2 suiza"
        },
        "PaymentBranchId": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "4200-4205"
        },
        "PaymentBranchName": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "ME MONEY EXCHANGE GMBH BASEL"
        },
        "PaymentBranchAddress": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "VOLTASTRASSE, 76; 4056 - BASEL"
        },
        "PaymentBranchPhone": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "0041613222007"
        },
        "PaymentBranchAuxId": {
            "attributes": {
                "xsi:type": "xsd:string"
            }
        },
        "OriginCountry": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "CHE"
        },
        "Reference": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "0037799L"
        },
        "AuxiliaryInfo": {
            "attributes": {
                "xsi:type": "xsd:string"
            },
            "$value": "{\"SenderBirthDate\":\"20000101\",\"SenderBirthPlace\":\"Bata\",\"SenderBirthCountry\":\"GNQ\"}"
        }
    }
]






