import axios from "axios"
import axiosConfigs from "../axiosConfig"



export const Get = async (AxiosConfigsToken) => {
    const res = await AxiosConfigsToken.get('/check_balance_sw')
    const data = res.data.data.data
    console.log(data,'sssssssss')
    return data
}